/* global
 autosize
 bsCustomFileInput
 checkRedirect
 gettext
 initHtmlTextarea
 removeText
 $x
 */

// #############################################################################
// GLOBAL VARS

const $body = $("body");

// #############################################################################
// FOCUS

$body.betterFocus({
  selector: "a, [tabindex]",
});

// #############################################################################
// TOOLTIP

$("[data-toggle=tooltip]").tooltip();

// #############################################################################
// FORM

$x.initFormDefaults = ($parent = $body) => {
  // File
  // TODO: Selber schreiben!
  bsCustomFileInput.init();

  // Autosize
  // TODO: Selber schreiben!
  autosize($("textarea", $parent));

  // HTML TinyMCE
  initHtmlTextarea($parent);

  // Range
  $("[type=range]", $parent).formRange();

  // Ajax upload
  const $ajaxUpload = new $x.AjaxUpload($("[data-ajax-upload]", $parent), {
    onUploadCompleted: function ($upload, $data) {
      $x.replaceHtml($data);
    },
  });

  $ajaxUpload.init();

  // File tree
  $("[data-file-tree]", $parent).formFileTree();

  // Form set

  $("[data-form-set]", $parent).formSet();

  return {
    ajaxUpload: $ajaxUpload,
  };
};

const $formDefaults = $x.initFormDefaults();

// Validation

$("[data-form]").formValidation({
  beforeSubmit: function () {
    $formDefaults.ajaxUpload.reset();
  },
  afterSubmit: function (request, $form, $data) {
    if ($data.submit === "success") {
      if ($data.redirect) {
        checkRedirect($data);
      } else {
        $x.replaceHtml($data);

        if ($data.toaster) {
          $body.toaster("updateToaster", $data.toaster);
        }
      }
    }
  },
});

// Wizard

$("[data-form-wizard]").formWizard();

// #############################################################################
// MODAL

$x.onModalOpenDefault = function ($modal) {
  $("[autofocus]", $modal).focus();
  $("[data-toggle=tooltip]", $modal).tooltip();

  const $formDefaults = $x.initFormDefaults($modal);

  // Validation

  $("[data-form]", $modal).formValidation({
    beforeSubmit: function () {
      $formDefaults.ajaxUpload.reset();
    },
    afterSubmit: function (request, $form, $data) {
      if ($data.submit === "success") {
        $modal.modal("hide");

        if ($data.redirect) {
          checkRedirect($data);
        } else {
          $x.replaceHtml($data);

          if ($data.toaster) {
            $body.toaster("updateToaster", $data.toaster);
          }

          $("[data-table]").xDataTable("reload");
        }
      }
    },
  });

  // Wizard

  $("[data-form-wizard]", $modal).formWizard();
};

$x.delegateEvent.on(document, "click", "[data-modal-link]", function (e) {
  e.preventDefault();

  $x.modal.open(this.href, {
    onModalOpen: $x.onModalOpenDefault,
  });
});

// #############################################################################
// DATA TABLE

$("[data-table]").xDataTable({
  options: {
    columns: [
      {
        data: "PLACEHOLDER_FIELDS",
      },
    ],
  },
  onInit: function ($table, $json) {
    // Custom code on init
  },
  onStateLoaded: function ($table, $data) {
    // Custom code on init
  },
  onDraw: function ($table) {
    // Custom code on redraw

    $("[data-toggle=tooltip]", $table).tooltip();
  },
  customizeCSV: function (csv) {
    // For customization read https://datatables.net/reference/button/csv

    return csv;
  },
  rowGroupStartRender: function ($table, $rows, html) {
    return html;
  },
  api: function ($table, $api) {
    // API: https://datatables.net/reference/api/
  },
});

// #############################################################################
// DOWNLOAD BLOB

$x.delegateEvent.on(document, "click", "[data-download]", function (e) {
  e.preventDefault();

  const $downloadBlob = new $x.DownloadBlob({
    onDownloadStarted: function ($data) {
      $body.toaster("updateToaster", $data.toaster);
    },
  });

  $downloadBlob.download(this.href);
});

// #############################################################################
// CLIPBOARD

$body.clipBoard({
  selector: "[data-clipboard]",
});

// #############################################################################
// TOASTER

$body.toaster({
  selector: "[data-toaster]",
});

// #############################################################################
// AUTO UPDATE HTML CONTENT

// TODO: Demo erstellen

$body.autoUpdateHtmlContent({
  selector: "[data-update-html-content]",
});
